<template>
<v-dialog v-if="!!item" width="800" v-model="dialog">
  <v-toolbar dense height="50" dark color="primary">
    <span class="text-h6">复制项目</span>
    <v-spacer></v-spacer>
    <v-icon @click="dialog=false">mdi-close</v-icon>
  </v-toolbar>
  <v-card>
    <v-container fluid>
      <v-stepper elevation="0" v-model="step">
        <v-stepper-header class="elevation-0">
          <v-stepper-step :complete="step>1" step="1">
            复制基本信息
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step>2" step="2">
            复制里程碑
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">
            复制任务
          </v-stepper-step>
        </v-stepper-header>
        <!-- basic information -->
        <v-stepper-content style="height:700px;" class="mt-n12" step="1">
          <v-container fluid>
            <!-- item name -->
            <div class="d-flex align-center">
              <div style="width:80px;" class="text-subtitle-2 grey--text mr-4"><span class="red--text">*</span> 名称</div>
              <v-text-field v-model="item.item_name"></v-text-field>
            </div>
            <!-- itme date range -->
            <div class="d-flex align-center">
              <div style="width:80px;" class="text-subtitle-2 grey--text mr-2">起止时间</div>
              <date-range-picker ref="picker" opens="center" :locale-data="{ 
                  firstDay: 1,
                  format: 'yyyy-mm-dd',
                  applyLabel: '确定',
                  cancelLabel: '取消',
                  daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                  monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                }" minDate="2018-01-01" maxDate="2050-12-31" :dateRange="{startDate: item.item_start_date, endDate: item.item_end_date}" :ranges="false" @update="setDateRange" :append-to-body="true">
                <template v-slot:input="picker">
                  <div style="width:600px;border-bottom:1px solid grey;" class="d-flex">
                    {{ picker.startDate | getFormatDate }} - {{ picker.endDate | getFormatDate }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-down</v-icon>
                  </div>
                </template>
              </date-range-picker>
            </div>
            <!-- item owner -->
            <div class="d-flex align-center mt-4">
              <div style="width:80px;" class="text-subtitle-2 grey--text mr-4"><span class="red--text">*</span> 负责人</div>
              <v-menu offset-y nudge-width="100">
                <template v-slot:activator="{attrs, on}">
                  <div class="d-flex align-center" style="width:600px;border-bottom:1px solid grey;" v-bind="attrs" v-on="on">
                    <user-avatar :name="getUserName(item.item_owner).charAt(0)" width="30px" height="30px" :userId="item.item_owner"></user-avatar>
                    <div class="ml-2">{{item.item_owner | username }}</div>
                  </div>
                </template>
                <user-picker @pick="setOwner"></user-picker>
              </v-menu>
            </div>
            <!-- item members -->
            <div class="d-flex align-center mt-4">
              <div style="width:80px;" class="text-subtitle-2 grey--text mr-4"><span class="red--text">*</span> 内部成员</div>
              <div>
                <v-menu offset-y nudge-width="100">
                  <template v-slot:activator="{attrs, on}">
                    <div class="d-flex align-center" style="width:600px;border-bottom:1px solid grey;">
                      <v-icon v-bind="attrs" v-on="on" large color="green">mdi-plus-circle</v-icon>
                      <user-avatar style="cursor:pointer;" @click.native="removeMember(p)" class="mx-1" v-for="(p, i) in item.item_participant.split(',').filter(u => u !== '')" :key="i" :name="getUserName(p).charAt(0)" width="30px" height="30px" :userId="p"></user-avatar>
                    </div>
                  </template>
                  <user-picker @pick="addMember"></user-picker>
                </v-menu>
                <div class="text-caption grey--text">如果有"企业外部人员"想要了解项目进展，或需要参与协作支持，可以创建项目后在"外部联系人"模块添加ta哦~</div>
              </div>
            </div>
            <!-- item followers -->
            <div class="d-flex align-center mt-4">
              <div style="width:80px;" class="text-subtitle-2 grey--text mr-4"><span class="red--text">*</span> 关注者</div>
              <div>
                <v-menu offset-y nudge-width="100">
                  <template v-slot:activator="{attrs, on}">
                    <div class="d-flex align-center" style="width:600px;border-bottom:1px solid grey;">
                      <v-icon v-bind="attrs" v-on="on" large color="green">mdi-plus-circle</v-icon>
                      <user-avatar style="cursor:pointer;" @click.native="removeFollower(p)" class="mx-1" v-for="(p, i) in item.item_followers.split(',').filter(u => u !== '')" :key="i" :name="getUserName(p).charAt(0)" width="30px" height="30px" :userId="p"></user-avatar>
                    </div>
                  </template>
                  <user-picker @pick="addFollower"></user-picker>
                </v-menu>
              </div>
            </div>
            <!-- item description -->
            <div class="d-flex align-center">
              <div style="width:80px;" class="text-subtitle-2 grey--text mr-4"> 项目描述</div>
              <v-text-field v-model="item.item_description"></v-text-field>
            </div>
            <div class="d-flex align-center mt-n3">
              <div style="width:80px;" class="text-subtitle-2 grey--text mr-4"> 可见范围</div>
              <v-radio-group v-model="item.item_visible_range" row>
                <v-radio :value="2">  
                  <template v-slot:label>
                    <div class="text-caption">仅相关成员</div>
                  </template>
                </v-radio>
                <v-radio :value="1">
                  <template v-slot:label>
                    <div class="text-caption">全公司</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <div style="position:absolute;bottom:10px;right:10px;" class="d-flex">
              <v-spacer></v-spacer>
              <v-btn class="mx-1 my-1" @click="dialog=false">取消</v-btn>
              <v-btn @click="step=2" color="primary" class="mx-1 my-1">下一步</v-btn>
            </div>
          </v-container>
        </v-stepper-content>
        <!-- step 2 -->
        <v-stepper-content style="height:700px;" class="mt-n12" step="2">
          <v-container fluid>
            <div style="height:35px;" class="d-flex align-center amber lighten-4 px-8">
              <div class="text-subtitle-2 mr-3">是否复制里程碑</div>
              <v-radio-group v-model="copyMilestone" row>
                <v-radio :value="true">
                  <template v-slot:label>
                    <div class="text-subtitle-2">复制</div>
                  </template>
                </v-radio>
                <v-radio :value="false">
                  <template v-slot:label>
                    <div class="text-subtitle-2">不复制</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <div style="height:550px;overflow:auto;" class="my-5">
              <div v-for="(ms, i) in clonedMilestones" :key="i" class="d-flex my-3 align-center">
                <v-menu :disabled="!copyMilestone" offset-y>
                  <template v-slot:activator="{attrs, on}">
                    <v-badge color="green" bordered icon="mdi-pencil" bottom overlap>
                      <v-avatar v-bind="attrs" v-on="on" class="white--text border-5" color="lime">{{ ms.ms_owner | username }}</v-avatar>
                    </v-badge>
                  </template>
                  <user-picker @pick="setMsOwner($event, i)"></user-picker>
                </v-menu>
                <v-card elevation="0" color="#F5F5F5" class="ml-3 px-2" height="70" width="700">
                  <div class="d-flex align-center">
                    <v-col cols="8">
                      <v-text-field :disabled="!copyMilestone" v-model="ms.ms_name"></v-text-field>
                    </v-col>
                    <v-col>
                      <date-range-picker ref="picker" opens="center" :disabled="!copyMilestone" control-container-class="grey lighten-4" :locale-data="{ 
                          firstDay: 1,
                          format: 'yyyy-mm-dd',
                          applyLabel: '确定',
                          cancelLabel: '取消',
                          daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                          monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                        }" minDate="2018-01-01" maxDate="2050-12-31" :dateRange="{startDate: ms.ms_start_date, endDate: ms.ms_end_date}" :ranges="false" @update="setMsRange($event, i)" :append-to-body="true">
                        <template v-slot:input="picker">
                          <div style="cursor:pointer;">
                            {{ picker.startDate | getFormatDate }} - {{ picker.endDate | getFormatDate }}
                          </div>
                        </template>
                      </date-range-picker>
                    </v-col>
                  </div>
                </v-card>
              </div>
              <div style="position:absolute;bottom:10px;right:10px;" class="d-flex">
                <v-spacer></v-spacer>
                <v-btn class="mx-1 my-1" @click="dialog=false">取消</v-btn>
                <v-btn @click="step=3" color="primary" class="mx-1 my-1">下一步</v-btn>
              </div>
            </div>
          </v-container>
        </v-stepper-content>
        <!-- step 3 copy tasks -->
        <v-stepper-content style="height:700px" step="3">
          <div style="height:35px;" class="d-flex align-center amber lighten-4 px-8">
            <div class="text-subtitle-2 mr-3">是否复制项目下的任务</div>
            <v-radio-group v-model="cloneTaskMode" row>
              <v-radio :value="0">
                <template v-slot:label>
                  <div class="text-subtitle-2">复制一级任务</div>
                </template>
              </v-radio>
              <v-radio :value="1">
                <template v-slot:label>
                  <div class="text-subtitle-2">复制全部任务</div>
                </template>
              </v-radio>
              <v-radio :value="2">
                <template v-slot:label>
                  <div class="text-subtitle-2">不复制</div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div class="pa-3" style="height:550px;overflow:auto;" v-if="cloneTaskMode==0">
            <v-data-table :headers="headers" :items="clonedTasks" hide-default-footer>
              <template v-slot:[`item.task_name`]="{item}">
                <v-text-field dense v-model="item.task_name" height="50"></v-text-field>
              </template>
              <template v-slot:[`item.task_owner`]="{item}">
                <v-menu :nudge-width="-350" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-icon large color="blue">mdi-account-outline</v-icon>
                      <span>{{ item.task_owner | username }}</span>
                    </div>
                  </template>
                  <UserPicker @pick="changeTaskOwner($event, item.task_id)" />
                </v-menu>
              </template>
              <template v-slot:[`item.date`]="{item}">
                <date-range-picker ref="picker" opens="left" control-container-class="" :locale-data="{ 
                                        firstDay: 1,
                                          format: 'yyyy-mm-dd',
                                          applyLabel: '确定',
                                          cancelLabel: '取消',
                                          daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                                          monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                                      }" minDate="2018-01-01" maxDate="2050-12-31" :dateRange="{startDate: item.task_start_date, endDate: item.task_end_date}" :ranges="false" @update="changeTaskDate($event, item.task_id)" :append-to-body="true">
                  <template v-slot:input="picker">
                    {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
                  </template>
                </date-range-picker>
              </template>
            </v-data-table>
          </div>
          <div style="height:550px;overflow:auto;" v-if="cloneTaskMode==1">
            <zk-table :data="clonedTasks" :columns="headers1" children-prop="task_tasks" index-text="#" :stripe="props.stripe" :border="props.border" :show-header="props.showHeader" :show-summary="props.showSummary" :show-row-hover="props.showRowHover" :show-index="props.showIndex" :tree-type="props.treeType" :is-fold="props.isFold" :expand-type="props.expandType" :selection-type="props.selectionType">
              <template slot="name" scope="scope">
                <v-text-field dense v-model="scope.row.task_name"></v-text-field>
              </template>
              <template slot="owner" scope="scope">
                <v-menu offset-y>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn small text v-bind="attrs" v-on="on">{{ scope.row.task_owner | username }}</v-btn>
                  </template>
                  <UserPicker @pick="changeTaskOwner($event, scope.row.task_id)" />
                </v-menu>
              </template>
              <template slot="date" scope="scope">
                <date-range-picker ref="picker" opens="left" control-container-class="" :locale-data="{ 
                                            firstDay: 1,
                                            format: 'yyyy-mm-dd',
                                            applyLabel: '确定',
                                            cancelLabel: '取消',
                                            daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                                            monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                                          }" minDate="2018-01-01" maxDate="2050-12-31" :dateRange="{startDate: scope.row.task_start_date, endDate: scope.row.task_end_date}" :ranges="false" @update="changeTaskDate($event, scope.row.task_id)" :append-to-body="true">
                  <template v-slot:input="picker">
                    {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
                  </template>
                </date-range-picker>
              </template>
            </zk-table>
          </div>
          <div style="height:550px;overflow:auto;" v-if="cloneTaskMode==2">
            <v-data-table :headers="headers" :items="clonedTasks" hide-default-footer>
              <template v-slot:[`item.task_name`]="{item}">
                <div class="grey--text">{{ item.task_name }}</div>
              </template>
              <template v-slot:[`item.task_owner`]="{item}">
                <div class="grey--text">{{ item.task_owner | username }}</div>                
              </template>
              <template v-slot:[`item.date`]="{item}">
                <div class="grey--text">
                  {{ item.task_start_date | getFormatDate }} - {{ item.task_end_date | getFormatDate }}
                </div>                
              </template>
            </v-data-table>
          </div>
          <div style="position:absolute;bottom:10px;right:10px;" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn class="mx-1 my-1" @click="dialog=false">取消</v-btn>
            <v-btn @click="agree" color="primary" class="mx-1 my-1">确定</v-btn>
          </div>
        </v-stepper-content>
      </v-stepper>
    </v-container>
  </v-card>
</v-dialog>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import {
  mapActions,
  mapGetters
} from 'vuex';
export default {
  name: 'ItemCopyWizard',
  components: {
    DateRangePicker,
    UserPicker: () => import('@/components/common/UserPicker.vue'),
    UserAvatar: () => import('@/components/common/UserAvatar.vue'),
  },
  props: ['item'],
  data: () => ({
    dialog: false,
    step: 1,
    copyMilestone: null,
    clonedMilestones: [],
    clonedTasks: [],
    cloneTaskMode: 0,
    headers: [{
        text: '任务名称',
        align: 'start',
        value: 'task_name',
        width: 300
      },
      {
        text: '负责人',
        value: 'task_owner',
        width: 150
      },
      {
        text: '起止时间',
        value: 'date'
      }
    ],
    headers1: [{
        label: "",
        prop: "",
        width: "100px;"
      },
      {
        label: "任务名称",
        prop: "name",
        type: "template",
        template: "name"
      },
      {
        label: "负责人",
        prop: "owner",
        type: "template",
        template: "owner",
        width: "100px;"
      },
      {
        label: "起止时间",
        type: "template",
        template: "date"
      },
    ],
    props: {
      stripe: false,
      border: false,
      showHeader: true,
      showSummary: false,
      showRowHover: true,
      showIndex: false,
      treeType: true,
      isFold: true,
      expandType: false,
      selectionType: false,
    },
  }),
  computed: {
    ...mapGetters('user', ['users']),
    ...mapGetters('milestone', ['milestones']),
    ...mapGetters('task', ['tasks']),
  },
  methods: {
    ...mapActions('milestone', ['getMilestonesCallback']),
    ...mapActions('task', ['getTasksCallback']),
    open() {
      this.dialog = true;
      this.copyMilestone = true;
      this.getMilestonesCallback({
        ms_parent_item: this.$route.query.id,
      }).then(res => {
        this.clonedMilestones = res;
      });
      this.step = 1;
      this.getTasksCallback({
        task_parent_item: this.$route.query.id,
      }).then(res => {
        this.clonedTasks = res;
      });
      this.cloneTaskMode = 0;
    },
    setDateRange(param) {
      let start = param.startDate;
      let end = param.endDate;
      start = new Date(start);
      end = new Date(end);
      moment(start).format('YYYY-MM-DD');
      moment(end).format('YYYY-MM-DD');
      this.item.item_start_date = start;
      this.item.item_end_date = end;
    },
    setOwner(e) {
      this.item.item_owner = e.user.id;
    },
    getUserName(id) {
      const index = this.users.findIndex(user => user.id == id);
      if (index !== -1) {
        return this.users[index].employeeName;
      }
      return '';
    },
    addMember(e) {
      let members = this.item.item_participant.split(',').filter(u => u !== '');
      const index = members.findIndex(u => u == e.user.id);
      if (index == -1) {
        members.push(e.user.id);
      }
      this.item.item_participant = members.join();
    },
    addFollower(e) {
      let members = this.item.item_followers.split(',').filter(u => u !== '');
      const index = members.findIndex(u => u == e.user.id);
      if (index == -1) {
        members.push(e.user.id);
      }
      this.item.item_followers = members.join();
    },
    removeMember(id) {
      let members = this.item.item_participant.split(',').filter(u => u !== '');
      const index = members.findIndex(u => u == id);
      members.splice(index, 1);
      this.item.item_participant = members.join();
    },
    removeFollower(id) {
      let members = this.item.item_followers.split(',').filter(u => u !== '');
      const index = members.findIndex(u => u == id);
      members.splice(index, 1);
      this.item.item_followers = members.join();
    },
    setMsOwner(e, index) {
      this.clonedMilestones[index].ms_owner = e.user.id;
    },
    setMsRange(e, index) {
      const start = new Date(e.startDate);
      const end = new Date(e.endDate);
      start.setDate(start.getDate() + 1);
      end.setDate(end.getDate() + 1);
      this.clonedMilestones[index].ms_start_date = moment(start).format('YYYY-MM-DD');
      this.clonedMilestones[index].ms_end_date = moment(end).format('YYYY-MM-DD');
    },
    changeTaskOwner(e, id) {
      const index = this.clonedTasks.findIndex(task => task.task_id == id);
      this.clonedTasks[index].task_owner = e.user.id;
    },
    changeTaskDate(e, id) {
      const start = new Date(e.startDate);
      const end = new Date(e.endDate);
      start.setDate(start.getDate() + 1);
      end.setDate(end.getDate() + 1);
      const index = this.clonedTasks.findIndex(task => task.task_id == id);
      this.clonedTasks[index].task_start_date = moment(start).format('YYYY-MM-DD');
      this.clonedTasks[index].task_end_date = moment(end).format('YYYY-MM-DD');
    },
    agree() {
      if(this.copyMilestone) {
        this.item.item_milestones = this.clonedMilestones;
      }
      if(this.cloneTaskMode !== 2) {
        this.item.item_tasks = this.clonedTasks;
      }
      this.$emit('agree');
      this.dialog = false;
    }
  },
  filters: {
    getFormatDate(date) {
      let day = new Date(date);
      return moment(day).format('YYYY-MM-DD');;
    }
  }
}
</script>
